import React, { Component } from 'react';
import './inhabit.scss';
import { i18n } from '../components/i18n';
import { withTranslation } from 'react-i18next';

import Layout from '../components/layout';
import CanvasBehavior from '../components/background-canvas-component';

let childrenImage = require('../images/children-inhabit.png');

class InhabitPage extends Component {
    render() {
        const { t, i18n } = this.props;

        return (
            <Layout>
                <div className="inhabit-page__wrapper">
                    <header>
                        <h1>
                            {i18n.language === 'ro' ? 'Vizionează' : 'Inhabit'}{' '}
                            <img src={childrenImage} alt="Happy children" />
                        </h1>
                    </header>

                    <CanvasBehavior />

                    <iframe
                        allowvr="yes"
                        allowfullscreen="yes"
                        scrolling="no"
                        src="https://aframe.io/aframe/examples/boilerplate/hello-world/"
                        width="600"
                        height="400"
                    />

                    <div className="title-desc-overlay">
                        <h3>Inhabit</h3>
                        <hr />
                        <p>{t('inhabit_desc')}</p>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default withTranslation()(InhabitPage);
